import React, { useState } from 'react';
import styled, { css } from 'styled-components'

import TeamMember from '../../elements/TeamMember'
import Title from '../../elements/Title'
import { ContentBlock } from '../../static/css/style'
const TeamWrap = styled.div`
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    margin:auto;
    @media screen and (max-width: 479px) {
        width: 100%;
        gap:40px;
    }
`;
const Team = (props) => {
    return (
        <div className="bodyBlack">
            <ContentBlock>
                {/* <Title size="xl" weight="bold">our practice../</Title>
            <Title size="xl" weight="normal" lineHeight="1.33" color="#191919" top="20vw" bottom="67px">Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod</Title> */}
                {/* <Title size="xl" weight="bold" bottom="1em">the founder</Title>
            <TeamWrap>
                {props.data.allWpTeam.nodes.map(({ node },index) => (                    
                    props.data.allWpTeam.nodes[index].teamCategory.nodes[0].name=="Founder"?  
                        <TeamMember data={props.data.allWpTeam.nodes[index]}></TeamMember>
                    :""              
                ))}
            </TeamWrap>    */}
                <Title size="xl" weight="bold" bottom="1em" color="white">the team...</Title>
                <TeamWrap>
                    {props.data.allWpTeam.nodes.map(({ node }, index) => (
                        props.data.allWpTeam.nodes[index].teamCategory.nodes[0].name == "Crew" ?
                            <TeamMember data={props.data.allWpTeam.nodes[index]}></TeamMember>
                            : ""
                    ))}
                </TeamWrap>

                <Title size="xl" weight="bold" bottom="1em" color="white">the advisors...</Title>
                <TeamWrap>
                    {props.data.allWpTeam.nodes.map(({ node }, index) => (
                        props.data.allWpTeam.nodes[index].teamCategory.nodes[0].name == "Advisor" ?
                            <TeamMember advisor="true" data={props.data.allWpTeam.nodes[index]} social="false"></TeamMember>
                            : ""
                    ))}
                </TeamWrap>
            </ContentBlock>
        </div>
    );
};
export default Team;