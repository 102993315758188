import React from "react"
import { Link } from "gatsby"
import { graphql } from 'gatsby'
import Layout from "../components/layout"
import SEO from "../components/seo"
import Team from "../views/Team/Team"
const TeamPage = ({ data }) => (
  <Layout>
    <SEO title="Our Team" url="https://www.kwazi.in/team" />
    <Team data={data}></Team>
  </Layout>
)

export default TeamPage
export const query = graphql`
  query{
    allWpTeam {
      nodes {
        Team {
          description
          designation
          facebookUrl
          instagramUrl
          linkedinUrl
          name
          gif {
            mediaItemUrl
          }
        }
        teamCategory {
          nodes {
            name
          }
        }
      }
    }
    }
`