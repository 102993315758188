import React, { useState } from 'react';
import styled, { css } from 'styled-components'
import { Link } from "gatsby"
//Images
import Pen from '../images/pen.png'
import Instagram from '../images/Instagram.png'
import Spotify from '../images/LinkedIn.png'
import Facebook from '../images/Facebook.png'
import Linkedin from '../images/LinkedIn.png'
import Designation from '../elements/Paragraph'
const FormWrap = styled.div`
    width:50%;
    display:flex;
    align-items:flex-start;
    justify-content:flex-start;
    flex-direction:column;
    position:relative;
`;
const SocialWrap = styled.div`
    width:50%;
    position:relative;
    display:flex;
    align-items:flex-end;
    justify-content:flex-start;
    flex-direction:column;
`;
const SocialIconWrap = styled.div`
    width:20%;
    position:relative;
    display:flex;
    justify-content: space-between;
    flex-direction:row;
    @media screen and (min-width: 1920px) {
      width:30%;
    }
    @media screen and (max-width: 991px) {
      width:60%;
    }
    @media screen and (max-width: 479px) {
      width:40%;
    }
`;
const SocialIcon = styled.img`
  left:auto;
  width:1.4vw;
  height:1.4vw;
  transition:all 0.3s ease;
  cursor:pointer;
  &:hover {
    transform:scale(1.2);
  }
  @media screen and (min-width: 1920px) {
    width:2em;
    height:2em;
  }
  @media screen and (max-width: 991px) {
    width:20px;
    height:20px;
  }
`;
const MemberWrap = styled.div`
    display:flex;
    width: 100%;  
    margin: 0%;
    padding: 0 26px 0 26px;
    margin-bottom:30px;
    @media screen and (max-width: 991px) {
      width: 100%;
    }
  @media screen and (max-width: 767px) {}
  @media screen and (max-width: 479px) {
      width: 100%;
      flex-direction:column;
      padding: 0 0px 0 0px;
  }
`;
const MemberImage = styled.img`
    height: 20vw;
    width:100%;
    position:absolute;
    top:0;
    bottom:0;
    right:0;
    left:0;
    background-color: transparent;
    padding-bottom: 0;
    margin-bottom: 2%;
    object-fit:cover;
    transform:translate(0.5em,-0.5em);
    @media screen and (max-width: 991px) {
      height: 250px;
    }
    @media screen and (max-width: 479px) {
      width: 100%;
    }
`;
const MemberImageWithoutBorder = styled.img`
    height: 20vw;
    width:30%;
    // background-color: #FAFAFA;
    border-radius:20px;
    padding-bottom: 0;
    margin-bottom: 2%;
    object-fit:cover;
    @media screen and (max-width: 991px) {
      height: 250px;
    }
    @media screen and (max-width: 479px) {
      width: 100%;
    }
`;
const Name = styled.h1`
    font-family: 'Montserrat', sans-serif;
    font-size: 30px;
    line-height: 1.17;
    margin-bottom:5px;
    text-align: left;
    color: #fff;
    font-weight:normal;
    @media screen and (max-width: 991px) {
      font-size: 1.8em;
    }
`;
const MemberContent = styled.div`
    padding-left:5%;
    display:flex;
    flex-direction:column;
    width:70%;
  
    @media screen and (max-width: 479px) {
      width: 100%;
      padding-left:0%;
      margin-top:${props => props.advisor ? '10px' : '0px'}
    }
`;
const BlueRect = styled.div`
    height: 20vw;
    width:100%;
    padding-bottom: 0;
    margin-bottom: 2%;
    @media screen and (max-width: 991px) {
      height: 250px;
    }
    @media screen and (max-width: 767px) {
      width: 98%;
    }
    background:#4ab9e9;    
    position:relative;
`;
const MemberImageBlock = styled.div`
    position:relative;
    width:30%;
    height:auto;
    @media screen and (max-width: 767px) {
      width:100%;
    }
`;
const TeamMember = (props) => {
  return (
    <MemberWrap>
      {props.advisor ?
        <MemberImageBlock>
          <BlueRect></BlueRect>
          <MemberImage src={props.data.Team.gif.mediaItemUrl}></MemberImage>
        </MemberImageBlock>
        :
        <MemberImageWithoutBorder src={props.data.Team.gif.mediaItemUrl}></MemberImageWithoutBorder>
      }


      <MemberContent advisor={props.advisor}>
        <Name>{props.data.Team.name}</Name>
        <Designation color="#fff" top="0px" marginBottom="0.8em" fontWeight="bold">{props.data.Team.designation}</Designation>
        {props.social == "false" ?
          ''
          :
          <SocialIconWrap>
            <a className="text-deco-none" href={props.data.Team.linkedinUrl}>
              <SocialIcon src={Linkedin}></SocialIcon>
            </a>
            <a className="text-deco-none" href={props.data.Team.instagramUrl}>
              <SocialIcon src={Instagram}></SocialIcon>
            </a>
            <a className="text-deco-none" href={props.data.Team.facebookUrl}>
              <SocialIcon src={Facebook}></SocialIcon>
            </a>
          </SocialIconWrap>}
        <Designation color="#fff" LineHeight="1.56">{props.data.Team.description}</Designation>
      </MemberContent>

    </MemberWrap>
  );
};
export default TeamMember;